import appStore from 'assets/png/appStore.png';
import googlePlay from 'assets/png/googlePlay.png';
import logoapp from 'assets/png/logoapp.png';
import logoStore from 'assets/png/logoStore.png';
import PhoneMockup from 'assets/png/dowlnload-app-zephir.png';
import ZephirDescription from 'components/common/ZephirDescription';
import React, { FC } from 'react';

interface Props {
  icon: string;
  text: string;
  image: string;
  title: string;
  lien: string;
}
export const GuideInstaltion: FC<Props> = ({ title, icon, image, text, lien }: Props) => {
  return (
    <div className="bg-white  w-1/2 py-4 rounded-20 lg:w-full">
      <img src={icon} alt="" className="w-9 2xl:w-6 object-contain	m-auto pb-4" />
      <div className="text-blue font-bold text-26 pb-8 text-center sm:text-xl">Installation sur {title}</div>

      <div className="px-8 py-4 leading-7	">
        1 - Cliquer sur le bouton «Télécharger l'application Guide Zéphir».
        <br />2 - L'application {text} se lance.
        <br />
        3 - Lancer l'installation de l'application.
        <br />
        4 - Attendre la fin de téléchargement : une barre de défilement apparaît vous indiquant l'avancement du
        téléchargement.
        <br />
        5 - Une fois l'application téléchargée, cliquer sur «Ouvrir» pour la lancer.
        <br />6 - Si c’est la première fois que vous connectez et que vous n’avez pas de code d’accès , cliquer sur
        «Obtenir un code d’accès» situé en dessous du bouton «Se connecter» sinon passer directement à l’étape 8.
        <br />7 - Répondre à l’email en fournissant un justificatif professionnel (numéro RPPS, carte professionnelle de
        formation, feuille de soins, carte d’étudiant ou certificat <br /> de scolarité …). Sinon envoyer ces
        justificatifs par email à <span className="text-blue-500">webmaster@splf.org</span> . <br /> Un code d’accès ou
        mot de passe vous sera renvoyé après analyse de ces documents.
        <br />
        8 - Une fois le code d’accès reçu , rentrer-le dans le champ mot de passe et renseigner votre Email.
        <br />
        9 - Cliquer sur «Activer».
        <br />
        10 - Vous pouvez maintenant utiliser votre application Guide Zéphir.
        <br />
      </div>
      <div className="border-t py-4 border-blue border-opacity-20	">
        <div className="text-blue text-center text-lg pb-3">Télécharger l'application Guide Zéphir</div>
        <div className="w-44  2xl:w-36 m-auto">
          <a href={lien}>
            <img src={image} alt="" className="w-44 object-contain	2xl:w-36 m-auto" />
          </a>
        </div>
      </div>
    </div>
  );
};

const Instalation = () => {
  return (
    <div className="container bg-grey-300 min-h-80">
      <ZephirDescription width="75px" className="justify-center h-14" />

      <div className="bg-grey-300 flex px-52 py-8 2xl:px-32 lg:px-16 sm:px-5 lg:flex-col">
        <img src={PhoneMockup} alt="" className="w-351  2xl:w-80 md:w-60" />

        <div className="pt-16 px-32 2xl:px-16 lg:px-0">
          <div className=" text-blue font-bold text-5xl lg:text-3xl	sm:text-2xl">Guide Zéphir</div>
          <div className="font-medium text-4xl lg:text:2xl pt-2 pb-4 sm:text-xl">
            Disponible en version web :{' '}
            <a
              href="https://guidezephir.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue font-bold text-26 pb-8 text-center sm:text-xl underline"
            >
              <span className="text-blue w-10 bg-yellow-100 p-2 rounded-5">guidezephir.fr</span>
            </a>{' '}
            et en application mobile
          </div>
          <div className="text-xl font-regular lg:text:xl 2xl:text-xl sm:text-base">
            Le guide Zéphir est un outil pour l’éducation thérapeutique à destination des professionnels de santé et des
            étudiants. Il est un annuaire des différents types de dispositifs classé par indications, classes
            thérapeutiques, DCI et enrichi de vidéos.
            <div className="py-8">Cette application est réservée au professionnels de santé.</div>
            <div className="text-blue font-medium text-3xl 2xl:text-2xl pb-4 sm:text-lg">
              Télécharger l'application Guide Zéphir sur
            </div>
            <div className="flex gap-x-6">
              <a href="https://apps.apple.com/fr/app/guide-z%C3%A9phir/id596400437">
                <img src={appStore} alt="" className="w-56 object-contain	2xl:w-52 sm:w-40" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.zephir.activity">
                <img src={googlePlay} alt="" className="w-56 object-contain 2xl:w-52 sm:w-40" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex px-52 py-8 2xl:px-32 bg-grey-100 gap-x-8 lg:gap-y-6 lg:flex-col lg:px-16 sm:px-4 w-full">
        <GuideInstaltion
          icon={logoapp}
          text="App Store"
          image={appStore}
          title="IOS"
          lien="https://apps.apple.com/fr/app/guide-z%C3%A9phir/id596400437"
        />
        <GuideInstaltion
          icon={logoStore}
          text="Google Play"
          image={googlePlay}
          title="Android"
          lien="https://play.google.com/store/apps/details?id=com.zephir.activity"
        />

        <div />
      </div>
    </div>
  );
};

export default Instalation;
