import gql from 'graphql-tag';
import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { List } from 'utils/generic';

export const medicamentsQuery = gql`
  query medicines($perPage: Int, $name: String) {
    medicines(perPage: $perPage, name: $name, sort: "name") {
      data {
        id
        name
        deviceType {
          name
        }
        small_title
        hybride
      }
    }
  }
`;

export interface MedicamentsArguments {
  perPage?: number;
  name?: string;
  medicine?: string;
}
export interface MedicamentsData {
  data: {
    id: string;
    name: string;
    small_title: string;
    hybride?: string;
    deviceType: {
      name: string;
    };
  }[];
}

export interface GeneralResultSearch {
  medicines: Medicine[];
  classes: { id: string; name: string }[];
  devices: { id: string; name: string }[];
  deviceTypes: { id: string; name: string }[];
  dcis: { id: string; name: string }[];
  indications: { id: string; name: string }[];
  labs: { id: string; name: string }[];
}

const GeneralSearchQuery = gql`
  query generalSearch($name: String) {
    generalSearch(name: $name) {
      medicines {
        id
        name
        small_title
        generic
        hybride
        lab {
          id
          name
        }
        device {
          name
        }
      }
      classes {
        id
        name
      }
      devices {
        id
        name
      }
      deviceTypes {
        id
        name
      }
      dcis {
        id
        name
      }
      indications {
        id
        name
      }
      labs {
        id
        name
      }
    }
  }
`;

export const useLazyMedicaments = (
  options: QueryHookOptions<{ medicines: MedicamentsData }, MedicamentsArguments> = {},
) => useLocalLazyQuery(medicamentsQuery, options);

export const useMedicaments = (options: QueryHookOptions<{ medicines: MedicamentsData }, MedicamentsArguments> = {}) =>
  useLocalQuery(medicamentsQuery, options);

export const useGeneralSearch = (
  options: QueryHookOptions<{ generalSearch: GeneralResultSearch }, MedicamentsArguments> = {},
) => useLocalQuery(GeneralSearchQuery, options);

export const useLazyGeneralSearch = (
  options: QueryHookOptions<{ generalSearch: GeneralResultSearch }, MedicamentsArguments> = {},
) => useLocalLazyQuery(GeneralSearchQuery, options);

export const medicineQuery = gql`
  query medicines(
    $perPage: Int
    $sort: String
    $page: Int
    $order: Int
    $deviceType: ID
    $dci: ID
    $indication: [ID]
    $start: String
    $classe: ID
    $inhalationChamber: String
    $generic: String
    $hybride: String
    $device: ID
    $lab: [ID]
  ) {
    medicines(
      perPage: $perPage
      sort: $sort
      page: $page
      order: $order
      deviceType: $deviceType
      dci: $dci
      indication: $indication
      start: $start
      classe: $classe
      inhalationChamber: $inhalationChamber
      generic: $generic
      hybride: $hybride
      device: $device
      lab: $lab
    ) {
      count
      page
      perPage
      data {
        id
        name
        small_title
        image
        hybride
        dose {
          name
        }
        video {
          url
          qrCode
        }
        classe {
          id
          name
        }
        device {
          id
          name
        }
        deviceType {
          id
          name
        }
        lab {
          id
          name
        }
        dci {
          id
          name
        }
        generic
        requests
      }
      totalPages
    }
  }
`;

export const medicineQueryCount = gql`
  query medicines(
    $perPage: Int
    $sort: String
    $page: Int
    $order: Int
    $deviceType: ID
    $dci: ID
    $indication: [ID]
    $start: String
    $classe: ID
    $inhalationChamber: String
    $generic: String
    $hybride: String
    $device: ID
    $lab: [ID]
  ) {
    medicines(
      perPage: $perPage
      sort: $sort
      page: $page
      order: $order
      deviceType: $deviceType
      dci: $dci
      indication: $indication
      start: $start
      classe: $classe
      inhalationChamber: $inhalationChamber
      generic: $generic
      hybride: $hybride
      device: $device
      lab: $lab
    ) {
      count
      page
      data {
        id
        classe {
          id
          name
        }
        device {
          id
          name
        }
        deviceType {
          id
          name
        }
        lab {
          id
          name
        }
        dci {
          id
          name
        }
        lab {
          id
          name
        }
      }
    }
  }
`;

const suggestedQuery = gql`
  query getSuggestion($_id: String!) {
    getSuggestion(_id: $_id) {
      classdiffdispositifdiff {
        id
        name
        small_title
        hybride
        device {
          id
          name
        }
        dci {
          id
          name
        }
        lab {
          id
          name
        }
        deviceType {
          id
          name
        }
        classe {
          id
          name
        }
      }
      classdiffdispositifmeme {
        id
        name
        small_title
        hybride
        device {
          id
          name
        }
        dci {
          id
          name
        }
        lab {
          id
          name
        }
        deviceType {
          id
          name
        }
        classe {
          id
          name
        }
      }
      dispositifdifferentt {
        id
        name
        small_title
        hybride
        device {
          id
          name
        }
        dci {
          id
          name
        }
        lab {
          id
          name
        }
        deviceType {
          id
          name
        }
        classe {
          id
          name
        }
      }
      dispositifmemee {
        id
        name
        small_title
        hybride
        device {
          id
          name
        }
        dci {
          id
          name
        }
        lab {
          id
          name
        }
        deviceType {
          id
          name
        }
        classe {
          id
          name
        }
      }
    }
  }
`;

export interface MedicinesArguments {
  perPage?: number;
  sort?: string;
  page?: number;
  order?: number;
  deviceType?: string;
  dci?: string;
  indication?: string[];
  start?: string;
  classe?: string;
  inhalationChamber?: string;
  generic?: string;
  hybride?: string;
  device?: string;
  lab?: string[];
}

export interface RequestType {
  createdAt: string;
  updatedAt: string;
  status: string;
}

export interface Medicine {
  id: string;
  name: string;
  small_title: string;
  generic?: string;
  hybride?: string;
  image: string;
  requests: number;
  request: RequestType[];
  video: {
    url: string;
    qrCode: string;
  };
  dose: {
    name: string;
  };
  lab: {
    id: string;
    name: string;
  };
  device: {
    id: string;
    name: string;
  };
  deviceType: {
    id: string;
    name: string;
  };
  dci: {
    id: string;
    name: string;
  };
  classe: {
    id: string;
    name: string;
  };
  comment: string;
}

export interface Medicines {
  data: Medicine[];
  count: number;
  page: number;
  totalPages: number;
}

export interface SuggestionsList {
  classdiffdispositifdiff: Medicine[];
  classdiffdispositifmeme: Medicine[];
  dispositifdifferentt: Medicine[];
  dispositifmemee: Medicine[];
}

export const useMedicine = (options: QueryHookOptions<{ medicines: Medicines }, MedicinesArguments> = {}) =>
  useLocalQuery(medicineQuery, options);

export const useMedicines = (options: QueryHookOptions<{ medicines: List<Medicine> }, {}> = {}) =>
  useLocalQuery(medicineQuery, options);

export interface MedicamentsFiltredArguments {
  perPage?: number;
  name?: string;
  page?: number;
  sort?: string;
  order?: number;
}

export const filtredMedicineQuery = gql`
  query medicinesFiltred($page: Int, $perPage: Int, $sort: String, $order: Int, $search: String) {
    medicinesFiltred(page: $page, sort: $sort, perPage: $perPage, order: $order, search: $search) {
      count
      page
      perPage
      data {
        id
        name
        small_title
        image
        hybride
        dose {
          name
        }
        video {
          url
          qrCode
        }
        classe {
          id
          name
        }
        device {
          id
          name
        }
        deviceType {
          id
          name
        }
        lab {
          id
          name
        }
        dci {
          id
          name
        }
        generic
        requests
        request {
          id
          createdAt
          updatedAt
          status
        }
      }
      totalPages
    }
  }
`;

export const useFiltredMedicines = (options: QueryHookOptions<{ medicinesFiltred: List<Medicine> }, {}> = {}) =>
  useLocalQuery(filtredMedicineQuery, options);

export const useLazyMedicines = (options: QueryHookOptions<{ medicines: Medicines }, MedicinesArguments> = {}) =>
  useLocalLazyQuery(medicineQuery, options);

export const useSuggestedMedicine = (
  options: QueryHookOptions<{ getSuggestion: SuggestionsList }, { _id: string }> = {},
) => useLocalQuery(suggestedQuery, options);

export const MedicamentsCountQuery = gql`
  query medicines(
    $device: ID
    $deviceType: ID
    $dci: ID
    $generic: String
    $hybride: String
    $classe: ID
    $indication: [ID]
    $lab: [ID]
  ) {
    medicines(
      device: $device
      deviceType: $deviceType
      dci: $dci
      lab: $lab
      generic: $generic
      hybride: $hybride
      classe: $classe
      indication: $indication
    ) {
      count
      page
      data {
        id
        name
        small_title
        image
        video {
          url
          qrCode
        }
        lab {
          name
        }
        device {
          id
          name
        }
        dose {
          name
        }
        deviceType {
          id
          name
        }
        classe {
          id
          name
        }
        dci {
          id
          name
        }
      }
      totalPages
    }
  }
`;

export const addFavouriteQuery = gql`
  mutation CreateFavorites($medicine: ID!) {
    createFavorites(medicine: $medicine) {
      id
      user {
        id
      }
      medicine {
        id
        name
        small_title
      }
    }
  }
`;

export interface AddFavouriteResponseData {
  id: string;
  user: {
    id: string;
  };
  medicine: {
    id: string;
    name: string;
    small_title: string;
  };
}

export const useMedicamentsCount = (
  options: QueryHookOptions<{ medicines: { count: number; data: Medicine[] } }, {}> = {},
) => useLocalQuery(MedicamentsCountQuery, options);

export const useAddFavourite = (
  options: MutationHookOptions<{ createFavorites: AddFavouriteResponseData }, { medicine: string }> = {},
) => useLocalMutation(addFavouriteQuery, options);

export const MedicamentsByIdQuery = gql`
  query medicine($id: ID!) {
    medicine(id: $id) {
      id
      name
      small_title
      useControl
      dose {
        name
        note
        copd
        number
        price
        reproducibility
      }
      image
      pdf
      MobileSensorExistence
      qrCode
      video {
        url
        qrCode
      }
      refill
      components {
        propellantGaz
        excipient
      }
      lab {
        id
        name
      }
      visualization {
        doseRemaining
        doseTaken
      }
      expires
      ready
      readyFirstUse
      price
      action {
        expire
        expireBronchodilator
        duration
        durationBronchodilator
      }
      coMarketing
      handToMouthCoordination
      inhalationChamber
      modality {
        inhalation
        utilisation
      }
      existence
      generic
      hybride
      repayment
      opinion
      rank
      MobileSensorExistence
      updatedDate
      refillCartridges
      percentageFineParticles
      dci {
        id
        name
      }
      classe {
        id
        name
      }
      deviceResistance
      deviceDiameter
      device {
        id
        name
      }
      deviceType {
        id
        name
      }
      indication {
        id
        name
      }
      comment
      commentAdmin
    }
  }
`;

export interface MedicamentsByIdData {
  medicine: {
    id: string;
    name: string;
    small_title: string;
    useControl: string;
    dose: {
      name: string;
      note: string;
      copd: string;
      number: string;
      price: string;
      reproducibility: string;
    };
    image: string;
    pdf: string;
    qrCode: string;
    video: {
      url: string;
      qrCode: string;
    };
    refill: string;
    components: {
      propellantGaz: string;
      excipient: string;
    };
    lab: {
      id: string;
      name: string;
    };
    visualization: {
      doseRemaining: string;
      doseTaken: string;
    };
    expires: string;
    ready: string;
    readyFirstUse: string;
    price: string;
    action: {
      expire: string;
      expireBronchodilator: string;
      duration: string;
      durationBronchodilator: string;
    };
    coMarketing: string;
    handToMouthCoordination: string;
    inhalationChamber: string;
    modality: {
      inhalation: string;
      utilisation: string;
    };
    existence: string;
    generic: string;
    hybride: string;
    repayment: string;
    opinion: string;
    rank: string;
    MobileSensorExistence: string;
    updatedDate: string;
    refillCartridges: string;
    percentageFineParticles: string;
    dci: {
      id: string;
      name: string;
    };
    classe: {
      id: string;
      name: string;
    };
    deviceType: {
      id: string;
      name: string;
    };
    deviceResistance: string;
    deviceDiameter: string;
    device: {
      id: string;
      name: string;
    };
    indication: [
      {
        id: string;
        name: string;
      },
    ];
    comment: string;
    commentAdmin: string;
  };
}

export const useMedicamentsById = (options: QueryHookOptions<MedicamentsByIdData> = {}) =>
  useLocalQuery(MedicamentsByIdQuery, options);

export const medicineLettersQuery = gql`
  query MedicineLetters(
    $device: ID
    $deviceType: ID
    $dci: ID
    $indication: [ID]
    $classe: ID
    $inhalationChamber: String
    $generic: String
    $hybride: String
    $lab: [ID]
  ) {
    medicineLetters(
      deviceType: $deviceType
      dci: $dci
      indication: $indication
      classe: $classe
      inhalationChamber: $inhalationChamber
      generic: $generic
      hybride: $hybride
      device: $device
      lab: $lab
    )
  }
`;

export const useMedicineLetters = (options: QueryHookOptions<{ medicineLetters: string[] }> = {}) =>
  useLocalQuery(medicineLettersQuery, options);

export const relatedMedicineQuery = gql`
  query RelatedMedicine(
    $medicine: ID!
    $sort: String
    $order: Int
    $deviceType: ID
    $dci: ID
    $indication: [ID]
    $lab: [ID]
    $start: String
    $classe: ID
    $inhalationChamber: String
    $generic: String
    $hybride: String
    $device: ID
  ) {
    relatedMedicine(
      medicine: $medicine
      sort: $sort
      order: $order
      deviceType: $deviceType
      dci: $dci
      indication: $indication
      lab: $lab
      start: $start
      classe: $classe
      generic: $generic
      hybride: $hybride
      inhalationChamber: $inhalationChamber
      device: $device
    ) {
      previous
      next
    }
  }
`;

interface RelatedMedicineArguments {
  medicine: string;
  sort?: string;
  order?: number;
  deviceType?: string;
  dci?: string;
  indication?: string[];
  lab?: string[];
  start?: string;
  classe?: string;
  generic?: string;
  hybride?: string;
  inhalationChamber?: string;
  device?: string;
}
interface RelatedMedicineResponse {
  relatedMedicine: {
    previous?: string;
    next?: string;
  };
}

export const useRelatedMedicine = (options: QueryHookOptions<RelatedMedicineResponse, RelatedMedicineArguments> = {}) =>
  useLocalQuery(relatedMedicineQuery, options);

const GET_MEDICAMENTS_HYBRIDES = gql`
  query {
    getMedicamentsHybrides {
      date
      categories {
        title
        items {
          name
          form
        }
      }
    }
  }
`;
export interface GetMedicamentsHybridesResponse {
  getMedicamentsHybrides: {
    date: string;
    categories: { title: string; items: { name: string; form: string }[] }[];
  };
}
export const useMedicamentsHybrides = () => {
  const { data, loading, error } = useLocalQuery<GetMedicamentsHybridesResponse>(GET_MEDICAMENTS_HYBRIDES, {
    fetchPolicy: 'no-cache',
  });

  // Supprimer les doublons dans les catégories et leurs items
  const filteredData = data?.getMedicamentsHybrides
    ? {
        ...data,
        getMedicamentsHybrides: {
          ...data.getMedicamentsHybrides,
          categories: data.getMedicamentsHybrides.categories
            .filter(
              (category, index, self) => index === self.findIndex((cat) => cat.title === category.title), // Filtrer les catégories dupliquées
            )
            .map((category) => ({
              ...category,
              items: category.items.filter(
                (item, index, self) => index === self.findIndex((it) => it.name === item.name && it.form === item.form), // Filtrer les items dupliqués
              ),
            })),
        },
      }
    : data;

  return { data: filteredData, loading, error };
};
