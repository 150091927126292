// import astensante from 'assets/sponsors/Asten.jpg';
// import boehring from 'assets/sponsors/Boehringer_Ingelheim.svg';
import Chiesi from 'assets/sponsors/Chiesi-logo.jpg';
import gsk from 'assets/sponsors/GSK_CMYK_2013.png';
// import pneumologie from 'assets/sponsors/pneumologie.png';
import { FC } from 'react';
import classNames from 'utils/classNames';
import classes from './sponsor.module.scss';

export const Sponsor: FC<React.HTMLProps<HTMLElement>> = () => {
  const images = [
    // { name: astensante, classNames: 'h-8 lg:h-8 2xl:h-6  sm:h-6 ' },
    //  { name: boehring, classNames: 'h-8 lg:h-8 2xl:h-8  sm:h-6 px-1 ' },
    { name: Chiesi, classNames: 'h-8 lg:h-8 2xl:h-6  sm:h-6 px-1 sm:pl-0  sm:px-0' },
    { name: gsk, classNames: 'h-6 sm:h-5 pl-1 lg:pl-2 sm:pl-0 mt-1 lg:h-7 2xl:h-6' },
    // { name: pneumologie, classNames: 'h-10  sm:h-8 pl-2 sm:pl-1 sm:pb-1  lg:h-8 2xl:h-8 ' },
  ];
  return (
    <div
      className={classNames(
        'sm:gap-1 mx-4 grid  m-auto sm:h-auto grid-flow-col sm:block',
        'h-14 sm:h-auto lg:px-8 y-2  px-4 2xl:px-4 sm:px-0',
        'rounded-5 bg-white sm:w-9/12 lg:content-center sm:content-start sm:min-w-342 min-w-690 2xl:min-w-127 items-center content-center ',
      )}
    >
      <div className="font-medium mt-1  text-sm lg:text-xs w-140 md:w-full  sm:w-auto lg:whitespace-nowrap sm:text-center	  text-grey-500 md:text-10 2xl:text-10 pr-2 sm:pl-2 sm:pr-0">
        Avec le soutien institutionnel des laboratoires
      </div>
      <div className="grid grid-flow-col sm:px-6  sm:grid-flow-row sm:flex sm:gap-y-6 h-9/12 lg:h-full gap-4  justify-self-end w-full  items-center">
        {images.map((img) => (
          // eslint-disable-next-line
          <div className="h-full  w-full   flex items-center ">
            <img className={classNames('object-contain', classes.image, img.classNames)} src={img.name} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};
