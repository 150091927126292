import { FunctionComponent } from 'react';
import { Link, useHistory, useLocation, matchPath } from 'react-router-dom';
import classNames from 'utils/classNames';
import SearchComponent from 'components/common/Search/Search';
import { decodeUri, encodeUri } from 'utils/url';
import { MedicamentsByIdData } from 'requests/medicaments';
import Search2 from 'components/common/Search2/Search2';
import classes from './Header.module.scss';
import ArrowNext from '../../../components/icons/ArrowRight';
import ArrowLeft from '../../../components/icons/ArrowLeft';

export type HeaderRouterProps = {
  pageTitle?: string;
  subTitle?: string;
  arrow?: boolean;
  smallTitle?: string;
  next?: string;
  previous?: string;
  previousPage?: string;
  className?: string;
  onClick?: () => void;
  onSubTitleClick?: () => void;
  data?: MedicamentsByIdData['medicine'];
  subsubTitle?: string;
};

const HeaderRouterHeader: FunctionComponent<HeaderRouterProps> = ({
  pageTitle,
  arrow,
  smallTitle,
  subTitle,
  next,
  previous,
  previousPage,
  className,
  onClick,
  onSubTitleClick,
  data,
  subsubTitle,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const uri = decodeUri(location.search);
  const indication = data?.indication.find((e) => e.id === uri?.indication);
  const search = useLocation();

  function renderComponent(pathname: any, titleHeader?: string, dataHeader?: string) {
    const minifyCondition =
      matchPath(location.pathname, `/notes/:id`) ||
      matchPath(location.pathname, `/notes`) ||
      matchPath(location.pathname, `/informations`) ||
      matchPath(location.pathname, `/gammeZephir`) ||
      matchPath(location.pathname, `/chambreInhalation`) ||
      matchPath(location.pathname, `/favoris`) ||
      uri.from === 'video' ||
      uri.from === 'home' ||
      matchPath(location.pathname, `/auteurs`);
    return (
      <>
        {!dataHeader ? (
          <>
            <Link
              to={pathname}
              className={classNames(
                ' pl-3 md:pl-1 ',
                `${matchPath(location.pathname, `/generic`) ? 'cursor-auto' : '  cursor-pointer underline'}`,
                classes.content,
              )}
            >
              {titleHeader}
            </Link>
            {titleHeader && minifyCondition && (
              <ArrowNext fill="#FFFFFF" height="12px" className="ml-3 md:ml-1 w-2.5 lg:w-6 sm:w-2.5 " />
            )}
          </>
        ) : (
          <>
            <span
              className={classNames(
                ` pl-3 md:pl-1 sm:text-xs sm:pl-2 text-center	${
                  uri.from === 'chambreInhalation' && 'underline cursor-pointer'
                }`,
                classes.content,
              )}
              onClick={() => {
                if (uri.from === 'chambreInhalation') {
                  history.push('/chambreInhalation');
                }
              }}
            >
              {' '}
              {titleHeader}
            </span>
            <ArrowNext fill="#FFFFFF" height="12px" className="ml-3 md:ml-1 w-2.5 lg:w-6 sm:w-2.5" />
            <Link
              to={pathname}
              className={classNames(
                ' pl-3 md:pl-1 md:text-sm sm:text-xs flex items-center cursor-pointer underline',
                classes.middlecontent,
              )}
            >
              {dataHeader}
            </Link>
          </>
        )}

        {minifyCondition && (
          <>
            <span className={classNames('pl-3 flex-1  md:text-xs items-center h-auto', classes.content)}>
              {subTitle}
              {smallTitle && <span> {smallTitle} </span>}
            </span>
          </>
        )}
      </>
    );
  }

  const renderHeader = () => {
    let args = ['/'] as Parameters<typeof renderComponent>;
    const { from, ...uriRest } = uri;
    const medId = data?.id || '';

    if (matchPath(location.pathname, `/notes/:id`)) {
      args = ['/notes', 'Mes Notes'];
    } else if (uri.from === 'favoris') {
      args = ['/favoris', 'Mes Favoris'];
    } else if (uri.from === 'note') {
      args = ['/notes', 'Mes Notes'];
    } else if (uri.from === 'deviceType') {
      args = [
        { pathname: '/types', search: encodeUri({ ...uriRest, medId }) },
        'Types de dispositifs',
        data?.deviceType.name,
      ];
    } else if (uri.from === 'device') {
      args = [
        { pathname: '/devices', search: encodeUri({ ...uriRest, medId }) },
        'Noms de dispositifs',
        data?.device.name,
      ];
    } else if (uri.from === 'classe') {
      args = [
        { pathname: '/classes', search: encodeUri({ ...uriRest, medId }) },
        'Classes thérapeutiques',
        data?.classe.name,
      ];
    } else if (uri.from === 'indication') {
      args = [{ pathname: '/indications', search: encodeUri({ ...uriRest, medId }) }, 'Indications', indication?.name];
    } else if (uri.from === 'dci') {
      args = [{ pathname: '/dci', search: encodeUri({ ...uriRest, medId }) }, 'DCI', data?.dci.name];
    } else if (matchPath(location.pathname, '/generic')) {
      args = [{ search: encodeUri({ ...uriRest, medId }) }, 'Médicaments génériques/hybrides'];
    } else if (uri.from === 'generic') {
      args = [{ pathname: '/generic', search: encodeUri({ ...uriRest, medId }) }, 'Médicaments génériques/hybrides'];
    } else if (uri.from === 'lab')
      args = [{ pathname: '/lab', search: encodeUri({ ...uriRest, medId }) }, 'Laboratoires', data?.lab.name];
    else if (uri.from === 'chambreInhalation')
      args = [
        `/chambreInhalation?section=${data?.deviceType.name.trim().split(' ').join('-')}`,
        'Chambres d’inhalation',
        data?.deviceType.name,
      ];
    return renderComponent(...args);
  };

  return (
    <>
      <div className="4xl:hidden 3xl:hidden 2xl:hidden xl:hidden lg:block md:block sm:block ">
        <SearchComponent />
      </div>
      <div
        className="flex flex-row w-full flex-1 justify-between items-center bg-yellow h-16 lg:h-28 px-28 lg:-mt-1  sm:px-5  sm:h-100  sm:flex-col sm:items-end lg:px-12"
        {...rest}
      >
        <div className=" flex flex-row justify-center sm:justify-start items-center text-white font-medium sm:grid sm:w-full sm:grid-flow-col text-sm md:text-sm sm:align-self: center">
          <div
            onClick={onClick}
            className={classNames(
              `pr-6 sm:text-xs sm:pr-3 ${search.search.length && ''} `,
              className,
              search.search.length && 'sm:flex ',
              `${
                uri.from === 'note' ||
                uri.from === 'favoris' ||
                matchPath(location.pathname, [`/notes`, `/favoris`, `/gammeZephir`, `/auteurs`, `/informations`])
                  ? 'no-underline'
                  : 'underline cursor-pointer'
              }`,
            )}
          >
            {pageTitle}
          </div>
          <ArrowNext fill="#FFFFFF" height="12px" className="w-2.5 lg:w-6 sm:w-2.5" />

          {!subsubTitle ? (
            renderHeader()
          ) : (
            <>
              <span className="pl-3  pr-3 md:px-1 sm:text-xs sm:text-center">{subTitle} </span>
              {Boolean(subsubTitle.trim().length) && (
                <ArrowNext fill="#FFFFFF" height="12px" className="w-2.5 lg:w-6 sm:w-2.5" />
              )}
              <span className="pl-3 md:pl-1 sm:pl-3 sm:text-xs ">{subsubTitle}</span>
            </>
          )}
        </div>
        <div className="flex sm:pb-5 sm:pt-5">
          <div className="3xl:grid 2xl:grid xl:grid lg:hidden md:hidden sm:hidden">
            <Search2 />
          </div>
          {arrow && (
            <div className=" flex flex-row  justify-center items-center space-x-8 ml-5 ">
              <ArrowLeft
                className={previous ? 'cursor-pointer' : 'cursor-not-allowed'}
                fill={previous ? '#FFFFFF' : 'gray'}
                width="15px"
                onClick={() => {
                  if (previous) history.push({ pathname: `/medicament/${previous}`, search: location.search });
                }}
              />

              <ArrowNext
                className={next ? 'cursor-pointer' : 'cursor-not-allowed'}
                fill={next ? '#FFFFFF' : 'gray'}
                width="15px"
                onClick={() => {
                  if (next) history.push({ pathname: `/medicament/${next}`, search: location.search });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderRouterHeader;
