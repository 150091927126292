import { useSuggestedMedicine } from 'requests/medicaments';
import { useParams } from 'react-router-dom';
import SuggestionCard from './SuggestionCard';

interface Props {
  currentClasseID: string | undefined;
  currentDeviceTypeID: string | undefined;
  currentDeviceID: string | undefined;
}

const Suggestions = ({ currentClasseID, currentDeviceTypeID, currentDeviceID }: Props) => {
  const params = useParams<{ id: string }>();
  if (currentClasseID && currentDeviceTypeID && currentDeviceID) {
    // e
  }

  const { data: alldata } = useSuggestedMedicine({ variables: { _id: params.id }, fetchPolicy: 'no-cache' });

  return (
    <div className="flex lg:flex-col lg:w-9/12 w-full justify-evenly  m-auto sm:w-11/12	">
      <SuggestionCard
        colorTitle="blue"
        titre="Classe identique"
        soustitre={[
          alldata?.getSuggestion.dispositifmemee.length ? 'Dispositif identique' : 'Dispositif identique',
          'Autres dispositifs',
        ]}
        data={alldata?.getSuggestion.dispositifmemee}
        secondData={alldata?.getSuggestion.dispositifdifferentt}
      />

      <SuggestionCard
        lab
        colorTitle="blue"
        titre="Autres classes"
        soustitre={[
          alldata?.getSuggestion.classdiffdispositifmeme.length ? 'Dispositif identique' : 'Dispositif identique',
          'Autres dispositifs',
        ]}
        data={alldata?.getSuggestion.classdiffdispositifmeme}
      />
    </div>
  );
};

export default Suggestions;
