import Button from 'components/Button';
import ArrowVideo from 'components/icons/ArrowVideo';
import classNames from 'utils/classNames';
import { Link, useLocation } from 'react-router-dom';
import Plus from 'components/icons/Plus';
import { useState, useContext } from 'react';
import Video from 'components/icons/Video';
import cardIdContext from 'contexts/CardIdContext';
import classes from './Card.module.scss';
import ModalVideo from '../../ux/ModalVideo/ModalVideo';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  footer?: string;
  medicineId?: string;
  smallTitle?: string;
  generic?: string;
  hybride?: string;
  deviceType?: string;
  medicinePhoto?: string;
  from?: string;
  videoData?: { url: string; qrCode: string };
  medidineDescription?: { classe: string; dci: string; device: string; dosage: string };
}

const Card: React.FC<CardProps> = ({
  title,
  medicineId,
  footer,
  className,
  medicinePhoto,
  deviceType,
  from,
  smallTitle,
  videoData = { url: '', qrCode: '' },
  generic,
  hybride,
  medidineDescription,
  ...rest
}) => {
  const location = useLocation();

  const [videoModal, setvideoModal] = useState(false);
  const { setCardId } = useContext(cardIdContext);
  return (
    <div
      id={medicineId}
      className={classNames(
        'flex flex-col text-blue-600 hover:text-white bg-white rounded-5 w-full',
        'hover:border-blue border border-grey hover:bg-blue min-h-250   ',
        classes.container,
        className,
      )}
    >
      <div
        {...rest}
        className={classNames(
          'flex-1 relative bg-cover 3_5xl:bg-contain bg-no-repeat bg-center  ',
          classes.backgroundImage,
        )}
        style={{
          backgroundImage: `url(${medicinePhoto})`,
        }}
      >
        <div className={classNames('relative flex items-center p-3', classes.video)}>
          <ArrowVideo fill="#014A8D" height={24} />
          <div className={classNames('flex place-self-end ml-auto')}>
            {hybride === 'oui' && (
              <div
                className={classNames(
                  'px-3 ml-auto flex  py-2 bg-green-400 text-white  text-xs font-medium	place-self-end rounded-3xl mr-2',
                  classes.footer,
                )}
              >
                <span className="px-1 "> Hybride </span>
              </div>
            )}
            {generic === 'oui' && (
              <div
                className={classNames(
                  'px-2 ml-auto flex  py-2 bg-yellow text-white  text-xs font-medium	place-self-end rounded-3xl',
                  classes.footer,
                )}
              >
                <span className="px-1 "> Générique </span>
              </div>
            )}
          </div>
          {/* eslint-disable-next-line */}
          {/* <img alt="fdsf" src={medicinePhoto} className="object-contain w-auto h-275 hidden" /> */}
        </div>

        <div
          className={classNames(
            'gap-3 w-full h-full hover:bg-blue absolute  flex-col items-center justify-center hidden  ',
            classes.btns,
          )}
        >
          <Link
            to={{
              pathname: `/medicament/${medicineId}`,
              search: from ? `${location.search}&from=${from}` : location.search,
            }}
            className="w-9/12"
          >
            <Button
              className="w-full m-auto"
              onClick={() => {
                setCardId(medicineId || '');
              }}
              variant="quinary"
              size="md"
            >
              <div className="flex items-center justify-center gap-5 w-full">
                <div className="w-6">
                  <Plus height={24} />
                </div>
                <div className="w-28 xl:text-sm "> Voir détails</div>
              </div>
            </Button>
          </Link>
          <Button className=" mt-1  w-9/12" variant="tertiary" size="md" onClick={() => setvideoModal(true)}>
            <div className="flex items-center gap-5">
              <div className="w-6">
                <ArrowVideo height={24} />
              </div>
              <div className="w-28 xl:text-sm"> Voir la vidéo</div>
            </div>
          </Button>

          <ModalVideo
            from={from}
            onClose={() => setvideoModal(false)}
            medidineDescription={medidineDescription}
            title={title}
            smallTitle={smallTitle}
            deviceType={deviceType}
            medicineId={medicineId}
            videoData={videoData}
            open={videoModal}
          />
        </div>
      </div>
      <div>
        <div className="  text-base 3_5xl:text-lg px-3 py-2 pb-0">
          <div className="font-bold">{title}</div>
          <div className="justify-between	flex">
            <div className="italic text-sm">{smallTitle}</div>
          </div>
        </div>
        <div className=" w-full">
          <div className="flex font-medium px-3 pt-2 pb-3">
            <div
              className={classNames(
                'flex-1 text-xs 3_5xl:text-lg min-w-max mr-2',
                footer?.trim().split(' ').length === 1 && 'truncate',
              )}
            >
              {footer}
            </div>
            <div className={classNames('text-blue flex flex-1 justify-end items-center', classes.footer)}>
              <Video height={13} className="lg:h-5" />
              <div className="pl-2.5 truncate xl:hidden ">Vidéo Éducative</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
