import Up from 'assets/svg/goUp.svg';
import ImagePreview from 'components/common/PreviewImage/ImagePreview';
import Suggestions from 'components/suggestion/SuggestionsList';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFavorite } from 'requests/Favoris';
import { useMedicamentsById, useRelatedMedicine } from 'requests/medicaments';
import { useMedicineNote } from 'requests/note';
import { Spinner } from 'utils/loading';
import { decodeUri } from 'utils/url';
import ModalVideo from '../../components/ux/ModalVideo/ModalVideo';
import HeaderRouter from './Components/HeaderRouter';
import MedicationHeader from './Components/MedicationHeader';
import MedicationTable from './Components/MedicationTable';

const DetailsContainer = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);
  const [videoModal, setvideoModal] = useState(false);
  const [readSectionActive, setreadSectionActive] = useState(false);

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 700) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  const [getFavorite, { data: favorite }] = useFavorite({
    fetchPolicy: 'no-cache',
  });

  const { data: noteData, refetch: refetchNote } = useMedicineNote({
    variables: { medicine: params.id },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    window.scroll({ top: 0 });
  }, [location]);

  const { data, called, loading } = useMedicamentsById({
    variables: { id: params?.id },
    fetchPolicy: 'no-cache',
  });
  const { data: relatedMedicineData } = useRelatedMedicine({
    variables: {
      ...uri,
      medicine: params.id,
      indication: uri.indication?.split(','),
      inhalationChamber: uri.inhalationChamber || uri?.from === 'chambreInhalation' ? 'oui' : undefined,
      deviceType:
        uri.inhalationChamber || uri?.from === 'chambreInhalation' || uri?.from === 'deviceType'
          ? data?.medicine?.deviceType.id
          : uri?.deviceType,
      generic: uri.generic || uri?.from === 'generic' ? 'oui' : undefined,
      hybride: uri.generic || uri?.from === 'generic' ? 'oui' : undefined,
      lab: uri.lab?.split(','),
      sort: 'name',
      order: uri.order === '-1' ? -1 : 1,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    getFavorite({ variables: { medicine: params.id } });
    refetchNote();
  }, [params.id]);

  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (loading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Spinner name="line-scale-pulse-out-rapid" color="#014A8D" />
      </div>
    );

  const getPageTitle = () => {
    let title = '';
    if (uri?.from === 'favoris' || uri?.from === 'note') {
      title = 'Mon espace';
    } else if (uri?.from === 'chambre') {
      title = "Chambres d'inhalation";
    } else if (uri.from === 'video') title = 'Vidéos éducatives';
    else title = 'Médicaments et liste de recherche';
    return title;
  };

  console.log({ data });
  return (
    <>
      {!data && called ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
        </div>
      ) : (
        <div className="bg-grey-300 w-full h-screen flex-1  ">
          <>
            <HeaderRouter
              onClick={() => {
                if (uri?.from === 'chambre') history.push('/chambreInhalation');
                else if (uri.from === 'video') history.push(`/videos?videoId=${data?.medicine?.id}`);
                else if (uri?.from !== 'favoris' && uri?.from !== 'note') history.push(`/?medId=${data?.medicine?.id}`);
              }}
              className={uri?.from !== 'favoris' && uri?.from !== 'note' ? 'underline cursor-pointer' : ''}
              data={data?.medicine}
              subTitle={data?.medicine.name}
              smallTitle={data?.medicine.small_title}
              pageTitle={getPageTitle()}
              previousPage="/"
              next={relatedMedicineData?.relatedMedicine.next}
              previous={relatedMedicineData?.relatedMedicine.previous}
              arrow={uri.from !== 'favoris'}
            />
            <div className="flex flex-row w-full px-28 py-12 lg:px-4 lg:px-5 sm:px-5 lg:flex-col  gap-20 2xl:gap-4 lg:m-auto lg:px-20 lg:gap-0">
              {width < 1000 && (
                <MedicationHeader
                  item={data?.medicine}
                  refetchNote={refetchNote}
                  note={noteData?.medicineNote}
                  onUpdate={() => getFavorite({ variables: { medicine: params.id } })}
                  favorite={favorite?.favorite}
                  onVideoOpen={setvideoModal}
                  name={data?.medicine.name}
                  deviceType={data?.medicine.small_title}
                  smallTitle={data?.medicine.small_title}
                  id={data?.medicine.id}
                  pdf={data?.medicine.pdf}
                  generic={data?.medicine?.generic}
                  hybride={data?.medicine?.hybride}
                />
              )}
              <div className="w-1/3 lg:w-full  flex-0   ">
                <ImagePreview src={data?.medicine?.image} alt="" className="rounded-5 w-100" />
                <div className="flex flex-row pt-4 justify-start items-start  w-full text-blue-900 text-lg	font-semibold 2xl:text-base lg:text-lg">
                  Description
                </div>
                <MedicationTable
                  dci={data?.medicine.dci}
                  classPharma={data?.medicine.classe}
                  deviceName={data?.medicine.device}
                  deviceType={data?.medicine.deviceType}
                  doseName={data?.medicine.dose}
                  dosageNote={data?.medicine.dose.note}
                  deviceDiameter={data?.medicine.deviceDiameter}
                  propellantGaz={data?.medicine.components.propellantGaz}
                  excipient={data?.medicine.components.excipient}
                  col
                />
              </div>
              <div className="flex flex-col flex-1">
                {width > 1000 && (
                  <MedicationHeader
                    item={data?.medicine}
                    note={noteData?.medicineNote}
                    onUpdate={() => getFavorite({ variables: { medicine: params.id } })}
                    onVideoOpen={setvideoModal}
                    favorite={favorite?.favorite}
                    name={data?.medicine.name}
                    deviceType={data?.medicine.small_title}
                    smallTitle={data?.medicine.small_title}
                    id={data?.medicine.id}
                    pdf={data?.medicine.pdf}
                    refetchNote={refetchNote}
                    generic={data?.medicine?.generic}
                    hybride={data?.medicine?.hybride}
                  />
                )}

                <div className="flex flex-col">
                  <MedicationTable
                    useControl={data?.medicine.useControl}
                    doseNumber={data?.medicine.dose.number}
                    refill={data?.medicine.refill}
                    doseRemaining={data?.medicine.visualization.doseRemaining}
                    doseTaken={data?.medicine.visualization.doseTaken}
                    readyFirstUse={data?.medicine.readyFirstUse}
                    ready={data?.medicine.ready}
                    handToMouthCoordination={data?.medicine.handToMouthCoordination}
                    inhalationChamber={data?.medicine.inhalationChamber}
                    modalitInhalation={data?.medicine.modality.inhalation}
                    modalitutilisation={data?.medicine.modality.utilisation}
                    refillCartridges={data?.medicine.refillCartridges}
                    coMarketing={data?.medicine.coMarketing}
                    actionExpire={data?.medicine.action.expire}
                    durationBronchodilator={data?.medicine.action.durationBronchodilator}
                    expireBronchodilator={data?.medicine.action.expireBronchodilator}
                    actionDuration={data?.medicine.action.duration}
                    doseReproducibility={data?.medicine.dose.reproducibility}
                    labName={data?.medicine.lab.name}
                    price={data?.medicine.price}
                    repayment={data?.medicine.repayment}
                    existence={data?.medicine.existence}
                    pdf={data?.medicine.pdf}
                    resistance={data?.medicine.deviceResistance}
                    percentageFineParticles={data?.medicine?.percentageFineParticles}
                    expires={data?.medicine.expires}
                    MobileSensorExistence={data?.medicine.MobileSensorExistence}
                    updatedDate={data?.medicine.updatedDate}
                    opinion={data?.medicine.opinion}
                  />
                  {Boolean(data?.medicine?.updatedDate.length) && (
                    <p className="text-xs mt-1 text-right lg:text-sm sm:text-xs">
                      <b>Mise à jour : </b>
                      <span className="text-xs lg:text-sm sm:text-xs">{data?.medicine.updatedDate}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Suggestions
              currentClasseID={data?.medicine?.classe?.id}
              currentDeviceID={data?.medicine?.device?.id}
              currentDeviceTypeID={data?.medicine?.deviceType?.id}
            />
          </>
          <p className="p-8" />
          <div className=" flex   items-center flex-1 py-4 bg-white flex-col text-center lg:text-justify sm:px-5 lg:px-20 mt-8">
            <div>
              <div className="font-regular text-center text-sm text-blue-600 flex flex-col lg:w-full leading-7 lg:text-xs lg:px-5 md:px-0 2xl:w-4/5	lg:w-full lg:leading-4 m-auto">
                <div className="mb-4">
                  En accord avec l’ANSM, cet outil éducationnel ne s’inscrit pas dans une démarche promotionnelle des
                  laboratoires pharmaceutiques concernés.
                </div>
                <div>
                  Pour protéger l’environnement , il est important de rapporter les inhalateurs en pharmacie vides ou
                  non. Collecte agréée Cyclamed.
                </div>
              </div>
            </div>
          </div>

          <ModalVideo
            onClose={() => setvideoModal(false)}
            medidineDescription={{
              classe: data?.medicine?.classe?.name,
              dci: data?.medicine?.dci?.name,
              device: data?.medicine?.device?.name,
              dosage: data?.medicine?.dose?.name,
            }}
            title={data?.medicine?.name}
            deviceType={data?.medicine?.deviceType.name}
            medicineId={data?.medicine?.id}
            videoData={data ? data?.medicine.video : { url: '', qrCode: '' }}
            open={videoModal}
            detail={false}
            smallTitle={data?.medicine?.small_title}
            dosageNote={data?.medicine.dose.note}
          />
        </div>
      )}
      <button
        className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <img alt="up" src={Up} height={30} />
      </button>
    </>
  );
};

export default DetailsContainer;
