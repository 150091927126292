import Chiesi from 'assets/sponsors/Chiesi-logo.jpg';

/* import pneumologie from 'assets/sponsors/pneumologie.png'; */

import gsk from 'assets/sponsors/GSK_CMYK_2013.png';
import logo from 'assets/svg/logoZephirWhite.svg';
import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import classes from './Footer.module.scss';
import FooterLink from './FooterLink';

const menu = [
  { title: 'Médicaments', pathname: '/' },
  { title: 'Vidéos éducatives', pathname: '/videos' },
  { title: 'Mes notes', pathname: '/notes' },
  { title: 'Mes favoris', pathname: '/favoris' },
];
const other = [
  { title: 'Informations', pathname: '/informations' },
  { title: 'Auteurs', pathname: '/auteurs' },
  { title: 'Gamme Zéphir', pathname: '/gammeZephir' },
];

const images = [
  { img: Chiesi, className: 'h-10  mt-2 sm:pr-1  lg:h-10 pt-2 sm:h-10 pr-0 sm:pr-0 mx-2 mb-4 sm:mx-1 sm:mx-0' },
  {
    img: gsk,
    className: 'h-8  mt-2 sm:pr-2  lg:h-9 pt-2 sm:h-8 pr-2 sm:pr-0 mx-0 mb-4 sm:mx-1 sm:mx-0',
  },
  /* { img: pneumologie, className: 'h-14 lg:h-14 pt-2 sm:h-10 pr-4 sm:pr-0 mx-2 sm:mx-0 mb-4' }, */
];

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <div className={classNames(classes.icons)}>
        {!location.pathname.includes('/auteurs') &&
          !location.pathname.includes('/404') &&
          !location.pathname.includes('/installation.html') && (
            <div className="flex items-center	 justify-center		px-28 lg:px-20 sm:px-1 xl:flex-col bg-white	lg-i py-2 xl:py-4 lg:items-start">
              <div className="flex flex-col lg:flex-col xl:m-auto 2xl:flex-row 2xl:items-center 3xl:flex-row 3xl:items-center  ">
                <div className="text-blue-600 font-medium 2xl:text-base text-lg pr-2 xl:text-center ">
                  Avec le soutien institutionnel des laboratoires
                </div>
                <div className="grid  justify-center	 grid-flow-col items-center	 lg:w-full ">
                  {images.map((i, key) => (
                    <img
                      key={key}
                      src={i.img}
                      alt=""
                      className={classNames(' object-contain ', classes.image, i.className)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
      </div>

      <footer className={classNames('min-h-96 flex  2xl:px-26 px-26  bg-blue', classes.container)}>
        <div
          className={classNames(
            ' pr-10 w-8/12	py-12  sm:py-2 sm:px-5 lg:px-14 lg:pr-16 px-28 bg-blue lg:w-full ',
            classes.logo,
          )}
        >
          <img src={logo} alt="" className="sm:w-44 	w-64 xl:w-212 	md:w-212 lg:w-212" />
          <div className="text-white text-sm ">
            Guide des thérapeutiques inhalées dans l'asthme et la BPCO chez l'adulte
          </div>
        </div>
        <div className="sm:px-0 pr-10  flex justify-center sm:flow-root px-28 bg-blue ">
          <div className={classNames(classes.menu, 'pb-3 py-12 px-48 lg:px-14  sm:pl-5')}>
            <FooterLink title="Menu" data={menu} />
          </div>

          <div className={classNames(classes.other, 'pb-3 py-12 px-8 lg:px-0 sm:px-5')}>
            <FooterLink title="Autres" data={other} />
          </div>
        </div>
      </footer>
      <div className="text-blue-200 text-xs font-semibold flex justify-center items-center h-10 bg-blue-750 ">
        Copyright {new Date().getFullYear()} SPLF.
        <a
          href="https://splf.fr/mentions-legales/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline pl-1"
        >
          {' '}
          Mentions légales
        </a>
      </div>
    </>
  );
};

export default Footer;
