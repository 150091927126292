import * as React from 'react';

// Déclarez un type étendu avec vos propriétés personnalisées
interface PastilleHybrideProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
  text?: string;
}

const PastilleHybride: React.FC<PastilleHybrideProps> = ({ fill = '#F8A609', text = 'Générique', ...props }) => {
  // Calculer dynamiquement la taille de la police en fonction de la longueur du texte
  const fontSize = Math.max(22, 250 / (text?.length || 1)); // Ajustement pour plus de visibilité

  return (
    <svg width="200" height="100" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0" y="0" width="200" height="100" rx="50" fill={fill} />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={fontSize}
        fontWeight="bold" // Gras ajouté ici
        fill="#FFFFFF"
      >
        {text}
      </text>
    </svg>
  );
};

export default PastilleHybride;
