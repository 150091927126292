import gql from 'graphql-tag';
import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { MedicineLaboratory, User } from './types';

export const updateMedicationsQuery = gql`
  mutation requestMedicineUpdate(
    $id: ID!
    $name: String
    $dose: MedicineDoseInput
    $refill: String
    $components: MedicineComponentsInput
    $visualization: MedicineVisualizationInput
    $expires: String
    $ready: String
    $readyFirstUse: String
    $price: String
    $action: MedicineActionInput
    $coMarketing: String
    $handToMouthCoordination: String
    $inhalationChamber: String
    $modality: MedicineModalityInput
    $existence: String
    $generic: String
    $hybride: String
    $repayment: String
    $opinion: Upload
    $rank: String
    $refillCartridges: String
    $percentageFineParticles: String
    $deviceResistance: String
    $deviceDiameter: String
    $lab: ID
    $dci: ID
    $classe: ID
    $device: ID
    $deviceType: ID
    $useControl: String
    $small_title: String
    $MobileSensorExistence: String
    $comment: String
    $updatedDate: String
    $valuesEmail: EmailRequestInput
  ) {
    requestMedicineUpdate(
      id: $id
      name: $name
      dose: $dose
      refill: $refill
      components: $components
      visualization: $visualization
      expires: $expires
      ready: $ready
      readyFirstUse: $readyFirstUse
      price: $price
      action: $action
      coMarketing: $coMarketing
      handToMouthCoordination: $handToMouthCoordination
      inhalationChamber: $inhalationChamber
      modality: $modality
      existence: $existence
      generic: $generic
      hybride: $hybride
      repayment: $repayment
      opinion: $opinion
      rank: $rank
      refillCartridges: $refillCartridges
      percentageFineParticles: $percentageFineParticles
      deviceResistance: $deviceResistance
      deviceDiameter: $deviceDiameter
      lab: $lab
      dci: $dci
      classe: $classe
      device: $device
      deviceType: $deviceType
      useControl: $useControl
      small_title: $small_title
      MobileSensorExistence: $MobileSensorExistence
      comment: $comment
      updatedDate: $updatedDate
      valuesEmail: $valuesEmail
    )
  }
`;
export const useUpdateMedicines = (options: MutationHookOptions<{ requestMedicineUpdate: string }> = {}) =>
  useLocalMutation(updateMedicationsQuery, options);

export interface MedicinesRequestsArguments {
  medicine: string;
}
export interface MedicinesArguments {
  perPage?: number;
  name?: string;
  medicine?: string;
  page?: number;
  order?: number;
  sort?: string;
}
export interface MedicinesRequest {
  id: string;
  fields: MedicineLaboratory;
  response: MedicineLaboratory;
  current: MedicineLaboratory;
  medicine: string;
  requestedBy: User;
  respondedBy: User;
  status: string;
  createdAt: string;
  updatedAt: string;
}
export interface Medicines {
  data: MedicinesRequest[];
  count: number;
  page: number;
  totalPages: number;
  perPage: number;
}

export const medicineRequestsQuery = gql`
  query medicineRequests($medicine: ID!, $perPage: Int, $page: Int, $sort: String, $order: Int) {
    medicineRequests(medicine: $medicine, perPage: $perPage, page: $page, sort: $sort, order: $order) {
      data {
        id
        requestedBy {
          lastName
          firstName
        }
        respondedBy {
          firstName
          lastName
        }
        createdAt
        updatedAt
        status
        response {
          name
          small_title
          useControl
          dose {
            name
            note
            copd
            number
            price
            reproducibility
          }
          MobileSensorExistence
          refill
          components {
            propellantGaz
            excipient
          }
          lab {
            id
            name
          }
          visualization {
            doseRemaining
            doseTaken
          }
          expires
          ready
          readyFirstUse
          price
          action {
            expire
            expireBronchodilator
            duration
            durationBronchodilator
          }
          coMarketing
          handToMouthCoordination
          inhalationChamber
          modality {
            inhalation
            utilisation
          }
          existence
          generic
          hybride
          repayment
          opinion
          rank
          MobileSensorExistence
          updatedDate
          refillCartridges
          percentageFineParticles
          dci {
            id
            name
          }
          classe {
            id
            name
          }
          deviceResistance
          deviceDiameter
          device {
            id
            name
          }
          deviceType {
            id
            name
          }
          comment
          commentAdmin
        }
      }
      totalPages
      perPage
      page
      count
    }
  }
`;

export const useMedicineRequests = (
  options: QueryHookOptions<{ medicineRequests: Medicines }, MedicinesArguments> = {},
) => useLocalQuery(medicineRequestsQuery, options);

export const medicineRequestQuery = gql`
  query medicineRequest($id: ID!) {
    medicineRequest(id: $id) {
      id
      current {
        name
        small_title
        updatedDate
        dose {
          name
          note
          copd
          price
          reproducibility
          number
        }
        refill
        expires
        useControl
        components {
          propellantGaz
          excipient
        }
        lab {
          name
          id
        }
        visualization {
          doseRemaining
          doseTaken
        }
        expires
        ready
        readyFirstUse
        price
        action {
          expire
          expireBronchodilator
          duration
          durationBronchodilator
        }
        coMarketing
        handToMouthCoordination
        inhalationChamber
        modality {
          inhalation
          utilisation
        }
        existence
        generic
        hybride
        repayment
        opinion
        rank
        refillCartridges
        percentageFineParticles
        dci {
          name
          id
        }
        classe {
          name
          id
        }
        deviceType {
          name
          id
        }
        deviceResistance
        deviceDiameter
        device {
          name
          id
        }
        MobileSensorExistence
        comment
      }
      fields {
        name
        small_title
        updatedDate
        dose {
          name
          note
          copd
          price
          reproducibility
          number
        }
        refill
        expires
        useControl
        components {
          propellantGaz
          excipient
        }
        lab {
          name
          id
        }
        visualization {
          doseRemaining
          doseTaken
        }
        expires
        ready
        readyFirstUse
        price
        action {
          expire
          expireBronchodilator
          duration
          durationBronchodilator
        }
        coMarketing
        handToMouthCoordination
        inhalationChamber
        modality {
          inhalation
          utilisation
        }
        existence
        generic
        hybride
        repayment
        opinion
        rank
        refillCartridges
        percentageFineParticles
        dci {
          name
          id
        }
        classe {
          name
          id
        }
        deviceType {
          name
          id
        }
        deviceResistance
        deviceDiameter
        device {
          name
          id
        }
        MobileSensorExistence
        comment
      }

      requestedBy {
        lastName
        firstName
        role
        email
        lab {
          id
          name
        }
      }
      respondedBy {
        firstName
        lastName
      }
      status
      createdAt
      updatedAt
      response {
        name
        small_title
        useControl
        dose {
          name
          note
          copd
          number
          price
          reproducibility
        }
        MobileSensorExistence
        refill
        components {
          propellantGaz
          excipient
        }
        lab {
          id
          name
        }
        visualization {
          doseRemaining
          doseTaken
        }
        expires
        ready
        readyFirstUse
        price
        action {
          expire
          expireBronchodilator
          duration
          durationBronchodilator
        }
        coMarketing
        handToMouthCoordination
        inhalationChamber
        modality {
          inhalation
          utilisation
        }
        existence
        generic
        hybride
        repayment
        opinion
        rank
        MobileSensorExistence
        updatedDate
        refillCartridges
        percentageFineParticles
        dci {
          id
          name
        }
        classe {
          id
          name
        }
        deviceResistance
        deviceDiameter
        device {
          id
          name
        }
        deviceType {
          id
          name
        }
        comment
        commentAdmin
      }
    }
  }
`;

export const useMedicineRequest = (
  options: QueryHookOptions<{ medicineRequest: MedicinesRequest }, { id: string }> = {},
) => useLocalLazyQuery(medicineRequestQuery, options);

export const refuseMedicineRequestQuery = gql`
  mutation refuseMedicineRequest(
    $id: ID!
    $name: String
    $dose: MedicineDoseInput
    $refill: String
    $components: MedicineComponentsInput
    $visualization: MedicineVisualizationInput
    $expires: String
    $ready: String
    $readyFirstUse: String
    $price: String
    $action: MedicineActionInput
    $coMarketing: String
    $handToMouthCoordination: String
    $inhalationChamber: String
    $modality: MedicineModalityInput
    $existence: String
    $generic: String
    $hybride: String
    $repayment: String
    $opinion: String
    $rank: String
    $refillCartridges: String
    $percentageFineParticles: String
    $deviceResistance: String
    $deviceDiameter: String
    $lab: ID
    $dci: ID
    $classe: ID
    $device: ID
    $deviceType: ID
    $useControl: String
    $small_title: String
    $MobileSensorExistence: String
    $updatedDate: String
    $comment: String
    $commentAdmin: String
    $valuesEmail: EmailInput
  ) {
    refuseMedicineRequest(
      id: $id
      name: $name
      dose: $dose
      refill: $refill
      components: $components
      visualization: $visualization
      expires: $expires
      ready: $ready
      readyFirstUse: $readyFirstUse
      price: $price
      action: $action
      coMarketing: $coMarketing
      handToMouthCoordination: $handToMouthCoordination
      inhalationChamber: $inhalationChamber
      modality: $modality
      existence: $existence
      generic: $generic
      hybride: $hybride
      repayment: $repayment
      opinion: $opinion
      rank: $rank
      refillCartridges: $refillCartridges
      percentageFineParticles: $percentageFineParticles
      deviceResistance: $deviceResistance
      deviceDiameter: $deviceDiameter
      lab: $lab
      dci: $dci
      classe: $classe
      device: $device
      deviceType: $deviceType
      useControl: $useControl
      small_title: $small_title
      MobileSensorExistence: $MobileSensorExistence
      updatedDate: $updatedDate
      comment: $comment
      commentAdmin: $commentAdmin
      valuesEmail: $valuesEmail
    ) {
      id
      fields {
        name
        small_title
        updatedDate
        comment
        dose {
          name
          note
          copd
          price
          reproducibility
        }
        refill
        expires
        useControl
        components {
          propellantGaz
          excipient
        }
        lab {
          name
        }
        visualization {
          doseRemaining
          doseTaken
        }
        expires
        ready
        readyFirstUse
        price
        action {
          expire
          expireBronchodilator
          duration
          durationBronchodilator
        }
        coMarketing
        handToMouthCoordination
        inhalationChamber
        modality {
          inhalation
          utilisation
        }
        existence
        generic
        hybride
        repayment
        opinion
        rank
        refillCartridges
        percentageFineParticles
        dci {
          name
        }
        classe {
          name
        }
        deviceType {
          name
        }
        deviceResistance
        deviceDiameter
        device {
          name
        }
        MobileSensorExistence
      }
    }
  }
`;

export const useRefuseMedicineRequest = (
  options: MutationHookOptions<{ refuseMedicineRequest: MedicinesRequest }> = {},
) => useLocalMutation(refuseMedicineRequestQuery, options);

export const acceptMedicineRequestQuery = gql`
  mutation acceptMedicineRequest(
    $id: ID!
    $name: String
    $dose: MedicineDoseInput
    $refill: String
    $components: MedicineComponentsInput
    $visualization: MedicineVisualizationInput
    $expires: String
    $ready: String
    $readyFirstUse: String
    $price: String
    $action: MedicineActionInput
    $coMarketing: String
    $handToMouthCoordination: String
    $inhalationChamber: String
    $modality: MedicineModalityInput
    $existence: String
    $generic: String
    $hybride: String
    $repayment: String
    $opinion: String
    $rank: String
    $refillCartridges: String
    $percentageFineParticles: String
    $deviceResistance: String
    $deviceDiameter: String
    $lab: ID
    $dci: ID
    $classe: ID
    $device: ID
    $deviceType: ID
    $useControl: String
    $small_title: String
    $MobileSensorExistence: String
    $updatedDate: String
    $comment: String
    $commentAdmin: String
    $valuesEmail: EmailInput
  ) {
    acceptMedicineRequest(
      id: $id
      name: $name
      dose: $dose
      refill: $refill
      components: $components
      visualization: $visualization
      expires: $expires
      ready: $ready
      readyFirstUse: $readyFirstUse
      price: $price
      action: $action
      coMarketing: $coMarketing
      handToMouthCoordination: $handToMouthCoordination
      inhalationChamber: $inhalationChamber
      modality: $modality
      existence: $existence
      generic: $generic
      hybride: $hybride
      repayment: $repayment
      opinion: $opinion
      rank: $rank
      refillCartridges: $refillCartridges
      percentageFineParticles: $percentageFineParticles
      deviceResistance: $deviceResistance
      deviceDiameter: $deviceDiameter
      lab: $lab
      dci: $dci
      classe: $classe
      device: $device
      deviceType: $deviceType
      useControl: $useControl
      small_title: $small_title
      MobileSensorExistence: $MobileSensorExistence
      updatedDate: $updatedDate
      comment: $comment
      commentAdmin: $commentAdmin
      valuesEmail: $valuesEmail
    ) {
      id
    }
  }
`;
export const useAcceptMedicineRequest = (
  options: MutationHookOptions<{ acceptMedicineRequest: MedicinesRequest }> = {},
) => useLocalMutation(acceptMedicineRequestQuery, options);
