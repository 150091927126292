import * as React from 'react';

function HybrideIcon({ fill = '#43A047', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" {...props}>
      <circle cx="32" cy="32" r="30" fill="#E8F5E9" stroke={fill} strokeWidth="2" />

      <path d="M28 20h8v20a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4V20h4z" fill={fill} />

      <path d="M24 40h16v6a2 2 0 0 1-2 2H26a2 2 0 0 1-2-2v-6z" fill={fill} />
      <path
        d="M36 28c4 0 8-3 8-6s-4-6-8-6M28 28c-4 0-8-3-8-6s4-6 8-6"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HybrideIcon;
