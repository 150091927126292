import HybrideDownload from '../icons/HybrideDonwload';
import PastilleHybride from '../icons/PastilleHybride';

const API_HOST = process.env.REACT_APP_PHOTO_API;

const PaveGeneriqueHybride = () => {
  const hybridPdfUrl = `${API_HOST}/uploads/pdf/20240806-registre-hybride-decision-aout-2024.pdf`;
  const genericPdfUrl = `${API_HOST}/uploads/pdf/20241021-generiques-repertoire-complet-18-10-2024.pdf`;

  return (
    <div className="flex flex-row  lg:flex-col  items-center  w-full my-6 justify-center gap-3">
      <div className="flex items-center gap-1 text-center md:text-left">
        <PastilleHybride width={120} height={28} />
        <span className="font-bold text-sm md:text-base text-blue">Médicaments Génériques :</span>
        <a
          href={genericPdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue font-semibold hover:underline flex items-center text-sm md:text-base"
        >
          <span>(Répertoire ANSM oct 2024</span>
          <HybrideDownload className="w-4 h-4 ml-1 text-blue" />
          <span>)</span>
        </a>
      </div>

      <div className="flex items-center gap-1   md:mt-0 text-center md:text-left">
        <PastilleHybride width={120} height={28} text="Hybride" fill="#43A047" />
        <span className="font-bold text-sm md:text-base text-blue">Médicaments Hybrides :</span>
        <a
          href={hybridPdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue font-semibold hover:underline flex items-center text-sm md:text-base"
        >
          <span>(Registre des groupes ANSM avr 2024</span>
          <HybrideDownload className="w-4 h-4 ml-1 text-blue" />
          <span>)</span>
        </a>
      </div>
    </div>
  );
};

export default PaveGeneriqueHybride;
