import React, { useContext, useEffect, useState } from 'react';
import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';
import ZephirDescription from 'components/common/ZephirDescription';
import { useLazyMedicines, useMedicamentsHybrides } from 'requests/medicaments';
import { useHistory, useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';
import Modal from 'components/common/Modal/Modal';
import { Spinner } from 'utils/loading';
import FilterAlpha from 'components/ux/FilterAlphabetical';
import classNames from 'utils/classNames';
import Card from 'components/common/Card/Card';
import Up from 'assets/svg/goUp.svg';
import cardIdContext from 'contexts/CardIdContext';
import Splf from '../../components/Splf';
import classes from '../Home/HomeContainer.module.scss';
import classes2 from '../HomeGeneric/HomeGeneric.module.scss';
import HeaderRouter from '../DetailsMedication/Components/HeaderRouter';
import PaveGeneriqueHybride from '../../components/ux/PaveGeneriqueHybride'; // Chemin correct selon votre structure

export const PER_PAGE = 100;
const MEDICAMENT_MODIFIERS = ['page', 'order', 'deviceType', 'dci', 'device', 'classe', 'indication', 'start'];

const GenericMedicine = () => {
  const { cardId, setCardId } = useContext(cardIdContext);
  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);
  const [responsive, setresponsive] = useState(window.innerWidth < 1000);
  const { setMenuStates } = useContext(MenuContext);
  const [readSectionActive, setreadSectionActive] = useState(false);
  const [dataCall, { data, loading: MedicineLoading }] = useLazyMedicines({
    fetchPolicy: 'no-cache',
  });
  useEffect(
    () => {
      dataCall({
        variables: {
          ...uri,
          sort: 'name',
          order: uri?.order === '-1' ? -1 : 1,
          generic: 'oui',
          hybride: 'oui',
        },
      });
    },
    MEDICAMENT_MODIFIERS.map((modifier) => uri[modifier]),
  );

  useEffect(() => {
    const el = document.getElementById(cardId || '');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, uri]);
  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  const checkResponsive = () => {
    if (window.innerWidth < 1000) {
      setresponsive(true);
    } else {
      setMenuStates(MenuStatesValues.CLOSE);
      setresponsive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', checkResponsive);
    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);
  const scrollToTop = () => {
    setCardId('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // État pour le modal
  // État pour le modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: hybrideData, loading: loadingHybrides, error: errorHybrides } = useMedicamentsHybrides();

  const { getMedicamentsHybrides } = hybrideData || {
    getMedicamentsHybrides: { date: '', categories: [] },
  };

  return (
    <>
      <div>
        <HeaderRouter
          onClick={() => {
            history.push('/');
          }}
          pageTitle=" Médicaments et liste de recherche"
        />
      </div>
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div
        className={classNames(
          'bg-grey-300 px-28 py-12 lg:px-20  sm:px-5 sm:py-1 ',
          !responsive ? classes2.container2 : classes.container,
        )}
      >
        <div className="flex justify-between lg:flex-col mb-5">
          <div className="text-grey-400 lg:text-22 font-bold  text-32 2xl:text-26">
            {' '}
            Médicaments génériques / hybrides
          </div>
          <div className="flex flex-col items-end justify-end lg:items-start ">
            <div className="text-sm 2xl:text-xs flex items-center pt-3 lg:pt-0 pr-0.5">
              <div className="text-yellow font-semibold">{data?.medicines.count}</div>
              <div className="text-blue-600  font-normal pl-1 ">
                Résultat{data?.medicines?.count! > 1 ? 's' : ''} trouvé{data?.medicines?.count! > 1 ? 's' : ''}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(classes.result)}>
          <FilterAlpha
            uri={{ ...uri, generic: 'oui' }}
            className={responsive ? 'mb-0 lg:bg-transparent border-none flex-0.9' : 'mb-6'}
            filterAllDatas={() => {
              const newUri = { ...uri };
              delete newUri.start;
              return history.replace({ pathname: location.pathname, search: encodeUri(newUri) });
            }}
            filterdDatas={(i) => {
              history.replace({ pathname: location.pathname, search: encodeUri({ ...uri, start: i }) });
            }}
          />
          <PaveGeneriqueHybride />
          <div className="grid  2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 grid-cols-111 gap-4 justify-between gap-y-5 pt-2.5 pb-10 relative">
            {MedicineLoading ? (
              <div className="absolute top-1/3 right-1/2">
                <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
              </div>
            ) : (
              <>
                <Splf />
                {data?.medicines.data.map((e) => {
                  return (
                    <>
                      <Card
                        from="generic"
                        key={e.id}
                        deviceType={e.deviceType.name}
                        smallTitle={e.small_title}
                        title={e.name}
                        footer={e.lab.name}
                        medicinePhoto={e.image}
                        medicineId={e.id}
                        videoData={e.video}
                        generic={e.generic}
                        hybride={e.hybride}
                        medidineDescription={{
                          classe: e.classe?.name,
                          dci: e.dci?.name,
                          device: e.device?.name,
                          dosage: e.dose?.name,
                        }}
                      />
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            className="px-4 py-2 bg-green-400 text-white font-semibold rounded-20 hover:bg-green-100"
            onClick={() => setIsModalOpen(true)}
          >
            Liste des médicaments génériques/hybrides novembre 2024
          </button>
        </div>
        <button
          className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
          onClick={() => scrollToTop()}
        >
          <img alt="up" src={Up} height={30} />
        </button>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        containerClassName="bg-gray-800 bg-opacity-50 flex  p-4 overflow-hidden"
        className="flex flex-col relative w-full max-w-4xl justify-start  h-80vh  p-2   items-start   bg-white rounded-lg shadow-lg  "
        responsivestate={responsive}
      >
        <div
          className="  
         bg-white flex items-center justify-between px-6 py-4 border-b border-gray-300"
        >
          <h2 className="text-xl font-bold text-gray-800">Médicaments Génériques / Hybrides Novembre 2024</h2>
        </div>
        <div className=" flex-1 py-4 overflow-auto  w-full">
          {loadingHybrides && <div className="text-center">Chargement...</div>}
          {errorHybrides && <div className="text-center text-red-500">Erreur: {errorHybrides.message}</div>}
          {getMedicamentsHybrides.categories.map((cat, idx) => (
            <div key={idx} className="mb-6">
              <h3 className="text-lg font-semibold text-green-100 mb-2">{cat.title}</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full border border-gray-300 bg-white">
                  <thead className="bg-gray-100 border-b border-gray-300">
                    <tr>
                      <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">Nom</th>
                      <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">Forme</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cat.items.map((item, i) => (
                      <tr key={i} className="border-b border-gray-200 hover:bg-gray-50">
                        <td className="py-2 px-4 text-sm text-gray-800">{item.name}</td>
                        <td className="py-2 px-4 text-sm text-gray-600">{item.form}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};
export default GenericMedicine;
